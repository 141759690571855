<template>
  <div class="grid"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove">
      <div class="grid-mask" @click.stop="close"></div>
      <div class="grid-main"
        v-show="!showQuanjing"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove">
          <div
            v-for="(item,index) in gridList"
            :key="index"
            class="grid-item"
            @touchstart="handleTouchStart"
            @touchmove="handleTouchMove"
            @click.stop="gridItemClick(item,index)"
          >
            <!-- 图片svg -->
            <div
                class="grid-icon-wrapper"
                @touchstart="handleTouchStart"
                @touchmove="handleTouchMove">
                <svg-icon class="grid-icon" v-if="index == activeIndex" :icon-class="'active-' + item.iconName" />
                <svg-icon class="grid-icon" v-else :icon-class="item.iconName" />
                <!-- <img class="grid-icon" :src="index == activeIndex ? item.activeIcon : item.icon" alt=""> -->
            </div>
            <!-- 文字 -->
            <div
                :class="index == activeIndex ? 'yellow-text' : ''"
                @touchstart="handleTouchStart"
                @touchmove="handleTouchMove">{{item.title}}</div>
          </div>

          <!-- 底部按钮 -->
          <div
            class="bottom-btn-container"
            @touchstart="handleTouchStart"
            @touchmove="handleTouchMove">
              <div
                class="bottom-btn bottom-btn-1"
                @touchstart="handleTouchStart"
                @touchmove="handleTouchMove"
                @click.stop="showQuanjing = true">
                  <img src="../../assets/images/quanjing.png" alt="">
                  <span>导览全景图</span>
              </div>
              <!-- 推荐路线按钮 -->
              <div
                class="bottom-btn recommend"
                @touchstart="handleTouchStart"
                @touchmove="handleTouchMove"
                @click.stop="recommendClick">
                  <!-- <img src="../../assets/images/xianlus.png" alt=""> -->
              </div>
          </div>
          <!-- 关闭按钮 -->
          <div class="close" @click.stop="close">
              <img src="../../assets/images/close.svg" alt="">
          </div>
      </div>
      <div class="grid-display" v-show="showQuanjing">
          <img
            src="../../assets/images/quanjing.jpg"
            @touchstart="handleTouchStart"
            @touchmove="handleTouchMove"
            alt="">
          <!-- 关闭全景图按钮 -->
          <div class="close2" @click.stop="showQuanjing = false">
              <img src="../../assets/images/close.svg" alt="">
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data (){
        return {
            gridList:[
                {
                    title: '景点',
                    id: 0,
                    iconName: 'icon-0'
                },
                {
                    title: '餐饮',
                    id: 1,
                    iconName: 'icon-1',
                    type: 1
                },
                {
                    title: '停车',
                    id: 2,
                    iconName: 'icon-2',
                    type: 3
                },
                {
                    title: '自行车',
                    id: 3,
                    iconName: 'icon-3',
                    type: 4
                },
                {
                    title: '娱乐',
                    id: 4,
                    iconName: 'icon-4',
                    type: 3
                },
                {
                    title: '服务',
                    id: 5,
                    iconName: 'icon-5',
                    type: 8
                },
                {
                    title: '出入',
                    id: 6,
                    iconName: 'icon-6',
                    type: 9
                },
                {
                    title: '卫生间',
                    id: 7,
                    iconName: 'icon-7',
                    type: 10
                },
                {
                    title: '游船',
                    id: 8,
                    iconName: 'icon-8',
                    type: 12
                },
                {
                    title: '电瓶车',
                    id: 9,
                    iconName: 'icon-9',
                    type: 13
                }
            ],
            activeIndex: 0,
            showQuanjing: false
        }
    },
    methods:{
        handleTouchStart(e){
            // console.log(e)
            e.stopPropagation()
            if(e.touches && e.touches.length>1){
                e.preventDefault()
            }
        },
        handleTouchMove(e){
            // console.log(e)
            if(e.touches && e.touches.length>1){
                e.preventDefault()
                e.stopPropagation()
            }
        },
        recommendClick(){
            this.$emit('recommendClick')
        },
        close(){
            this.$emit('closeGrid')
        },
        //grid点击
        gridItemClick(item,index){
            this.activeIndex = index
            this.$emit('gridItemClick', item)
        }
    }
}
</script>

<style scoped lang="scss">
    .grid-mask{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .3);
        z-index: 1000;
    }
    .grid-main{
        width: 75%;
        height: 60%;
        padding: 100px 60px 0;
        background: #28282A;
        border-radius: 15px;
        color: #A6A6A6;
        position: absolute;
        top: 17%;
        right: 30px;
        z-index: 1001;
        text-align: left;
        .grid-item{
            width: 25%;
            height: 180px;
            display: inline-block;
            text-align: center;
            font-size: 24px;
            margin-bottom: 110px;
            .grid-icon{
                width: 85px!important;
                height: 80px!important;
            }
            div{
                padding-top: 20px;
            }
            .yellow-text{
                color: rgba(251,229,74, 1);
            }
        }
        .bottom-btn-container{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            .bottom-btn{
                width: 262px;
                border-radius: 6px;
                background: #fff3f0;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #333;
                letter-spacing: 3px;
                margin-bottom: 15px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .bottom-btn-1{
                img{
                    width: 27px;
                    height: 27px;
                    margin-right: 15px;
                }
            }
            .recommend{
                background: url('../../assets/images/xianlus.png') no-repeat;
                background-size: 100% 100%;
            }
        }
        .close{
            width: 80px;
            height: 80px;
            position: absolute;
            bottom: -90px;
            left: 50%;
            transform: translateX(-50%);
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .grid-display{
        width: 100%;
        height: 500px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 1000;
        img{
            width: 100%;
            // height: 100%;
        }
    }
    .close2{
        width: 80px;
        height: 80px;
        position: absolute;
        bottom: -186px;
        left: 50%;
        transform: translateX(-50%);
        img{
            width: 100%;
            height: 100%;
        }
    }
</style>