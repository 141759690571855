<template>
    <div class="map-modal" :style="{top: top + '%'}" v-if="mapItem">
        <!-- 关闭按钮 -->
        <svg-icon icon-class="close" className='close-icon' @click.stop="closeMapModal" />
        <!-- 拖动区域 -->
        <div class="touch" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
            <img src="../../assets/images/touch.png" class="touch-icon" alt="">
        </div>
        <div class="main">
            <div class="mapitem-name">{{mapItem.name}}</div>
            <div class="mapitem-img">
                <img v-if="mapItem.imgUrl" class="banner" :src="fileUrl + mapItem.imgUrl" alt="">
                <div class="img-bottom" v-if="mapItem.imgUrl">
                    <svg-icon v-if="mapItem.audioUrl && !playing" icon-class="play" @click.stop="playBtnClick" className="play-icon" />
                    <svg-icon v-if="mapItem.audioUrl && playing" icon-class="pause" @click.stop="playBtnClick" className="play-icon" />
                    <span v-if="mapItem.audioUrl">{{duration}}</span>
                    <!-- <svg-icon icon-class="vr" className="vr-icon" />
                    <span>全景</span> -->
                </div>
            </div>
            <div class="item-bottom">
                <span class="item-address">{{mapItem.address}}</span>
                <span @click="goThere(mapItem)">去这里 ></span>
            </div>
        </div>
        <audio
            v-if="mapItem.audioUrl"
            :src="fileUrl + mapItem.audioUrl"
            class="audio"
            ref="audio"
            @canplay="onCanPlay"
            @ended="audioEnded"
            @pause="audioPause"
            @timeupdate="audioTimeUpdate"></audio>
    </div>
</template>

<script>
export default {
    name:'mapModal',
    props:{
        mapItem: {}
    },
    data(){
        return {
            top: 30,
            fileUrl: 'https://jqadmin.tangshannanhu.com/api/upload/',
            clientHeight: 1920,
            duration:'00:00',
            durationTime: 0,
            playing: false
        }
    },
    mounted(){
        this.clientHeight = document.body.clientHeight || window.screen.height
        console.log('浏览器高度：',this.clientHeight)
    },
    methods:{
        goThere(item){
            this.$emit('goThere', item)
        },
        //转换时长的时、分
        tranTime(time,type = 0){
            if(type == 1){
                return String(Math.floor(time / 60)).padStart(2,0)
            }
            return String(Math.floor(time % 60)).padStart(2,0)
        },
        //播放、暂停
        playBtnClick(){
            let audio = this.$refs.audio
            if(audio.paused){
                audio.play()
                this.playing = true
            }else{
                audio.pause()
                this.playing = false
            }
            
        },
        //音频播放暂停后设置playing
        audioPause(){
            console.log("暂停了+++++++++++")
            this.playing = false
        },
        //监听音频播放时间变化，更新页面显示剩余时间
        audioTimeUpdate(e){
            // console.log('时间变化+++++',e)
            let ctime = e.target.currentTime
            let downTime = this.durationTime - ctime
            let min = this.tranTime(downTime,1)
            let sec = this.tranTime(downTime)
            min = min <= 0 ? '00' : min
            sec = sec <= 0 ? '00' : sec
            this.duration = `${min}:${sec}`
        },
        //监听音频可以播放事件，获取时长
        onCanPlay(e){
            // console.log('播放2+++++++++',e)
            let duration = e.target.duration
            this.durationTime = duration
            let min = this.tranTime(duration,1)
            let sec = this.tranTime(duration)
            this.duration = `${min}:${sec}`
        },
        //播放完毕事件
        audioEnded(){
            let duration = this.durationTime
            let min = this.tranTime(duration,1)
            let sec = this.tranTime(duration)
            this.duration = `${min}:${sec}`
            this.playing = false
        },
        //关闭组件
        closeMapModal(){
            let audio = this.$refs.audio
            if(audio){
               audio.pause() 
            }
            this.$emit('closeMapModal')
        },
        handleTouchStart(e){
            // console.log(e)
        },
        //拖动，top不小于25%，不大于60%
        handleTouchMove(e){
            // console.log(e)
            let mh = e.changedTouches[0]['pageY']
            let rate = mh / this.clientHeight
            if(rate <= 0.25 || rate >= 0.60){
                return false;
            }
            this.top = rate * 100
        },
        handleTouchEnd(e){
            // console.log(e)
        }
    }
}
</script>

<style scoped lang="scss">
    .map-modal{
        width: 100%;
        max-height: 580px;
        background: #28282A;
        border-radius: 30px 30px 0 0;
        position: absolute;
        top: 30%;
        left: 0;
        right: 0;
        z-index: 1000;
        // transform: translateY(-50%);
        color: #fff;
        font-weight: bold;
        text-align: center;
        padding-bottom: 60px;
        .mapitem-name{
            font-size: 28px;
            padding-top: 15px;
        }
    }
    .mapitem-img{
        height: 400px;
        width: 96%;
        padding: 10px 5px;
        margin:20px auto;
        overflow: hidden;
        position: relative;
        .banner{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;
        }
        .img-bottom{
            width: 100%;
            height: 44px;
            line-height: 44px;
            background: rgba(1,1,1,.5);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            box-sizing: border-box;
            padding-right: 30px;
            text-align: right;
            font-size: 13px;
            color: #FBE54A;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .play-icon{
                width: 46px;
                height: 46px;
            }
            .vr-icon{
                width: 46px;
                height: 46px;
                transform: scale(0.4);
            }
            span{
                margin-right: 10px;
            }
        }
    }
    .item-bottom{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 19px;
        font-weight: bold;
        color: rgba(251,229,74, 1);
        margin-top: 10px;
        padding: 0 50px;
        .item-address{
            color: #fff;
        }
    }
    .touch{
        height: 50px;
        background: #28282A;
        border-radius: 30px 30px 0 0;
        .touch-icon{
            display: block;
            margin: 0 auto;
            position: absolute;
            left: 50%;
            top: 20px;
            transform: translate(-50%,0);
        }
    }
    .close-icon{
        width: 80px!important;
        height: 80px!important;
        position: absolute;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
    }
    .audio{
        opacity: 0;
    }
</style>