<template>
<div>
  <div class="container">
      <!-- 切换南湖、开滦 -->
      <div class="map-top fx fa fb">
          <div
          v-for="(item,index) in quyus"
          :key="index"
          :class="topIndex == item.id ? 'top-btn btn-active' : 'top-btn'"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @click.stop="mapTopClick(item)">
          {{item.name}}
          </div>
      </div>
      <!-- 地图区域 -->
      <div class="map-container">
        <Map
            :centerLatLng="centerLatLng"
            :centerLatLng2="centerLatLng2"
            @markerClick="markerClick"
            :markers="markers"
            :latLng="lines"
        />
       
      </div>
      
  </div>
  <div @touchstart="handleTouchStart" @touchmove="handleTouchMove">
        <!-- 切换区域 -->
        <div class="grid-parent">
            <Grid
                v-show="showGrid"
                @closeGrid="closeGrid"
                @gridItemClick="gridItemClick"
                @recommendClick="recommendClick"
            />
            <!-- 侧边按钮 -->
            <div class="side-btn" @click.stop="openGrid">
                <svg-icon :icon-class='"menu-"+activeGrid.id' className='icon' color="#fff" style="width:80px;height:80px;"></svg-icon>
            </div>
        </div>
        <!-- 展示列表区域 -->
        <list-scroll v-show="showListScroll" @closeListScroll="closeListScroll" :mapList="mapList" @goThere="goThere" />
        <!-- 地图markers点击弹窗 -->
        <map-modal v-show="showMapModal" @closeMapModal="closeMapModal" :mapItem="mapItem" @goThere="goThere" />
        <!-- 路线列表 -->
        <Lines v-if="showLines" :linesList="linesList" :currentLine="currentLine" @changeIndex="changeIndex" @closeClick="closeClick" />
        <Toast :showToast="showToast" @changeToast="changeToast" />
        <div v-show="qrcode" class="qrcode">
            <vue-qr :text="qrcode" :size="100" :margin="5"></vue-qr>
            <div>微信扫描上方二维码查看景点详情</div>
            <!-- 关闭二维码 -->
            <svg-icon icon-class="close" className='close' @click.stop="closeQrcode" />
        </div>
    </div>
  </div>
</template>

<script>
import Map from '@/components/Map/CustomMap.vue'
import Grid from '@/components/Grid/Grid.vue'
import ListScroll from '@/components/ListScroll/ListScroll.vue'
import MapModal from '@/components/MapModal/MapModal.vue'
import Lines from '@/components/Lines/Lines.vue'
import { getList, getSceneList, getRestList, getRecommendList } from '@/api/index.js'
import vueQr from 'vue-qr'
export default {
    components:{
        Map,
        Grid,
        ListScroll,
        MapModal,
        Lines,
        vueQr
    },
    data(){
        return{
            topIndex: 0,
            centerLatLng:{},
            centerLatLng2:{},
            quyus:[
                {
                    id:0,
                    name:"唐山南湖旅游景区",
                    lat:"39.6023074755",
                    lng:"118.1581401495",
                    scale:14,
                    neLat:"39.639180",
                    neLng:"118.196255",
                    swLat:"39.540232",
                    swLng:"118.136957",
                    type: 1
                },
                {
                    id:1,
                    name:"开滦国家矿山公园",
                    lat:"39.622176",
                    lng:"118.198769",
                    scale:16,
                    neLat:"39.626890",
                    neLng:"118.206142",
                    swLat:"39.614769",
                    swLng:"118.196875",
                    type: 2
                }
            ],
            showGrid: false,
            activeGrid:{
                title: '景点',
                icon: require('@/assets/images/icon-0.svg'),
                activeIcon: require('@/assets/images/active-icon-0.svg'),
                id: 0
            },
            mapList:[],
            showListScroll: false,
            markers:[],
            mapItem: null,
            lines:[],
            linesList:[],
            showLines: false,  //是否展示底部路线列表
            showToast: false,
            currentLine: 0,
            qrcode:''
        }
    },
    computed:{
        showMapModal(){
            if(this.showLines && this.mapItem){
                this.showLines = false
            }
            if(this.mapItem) return true;
            return false;
        }
    },
    mounted(){
        this.centerLatLng = this.quyus[0]
        this.changeList(this.quyus[0])
        // let hash = location.hash
        // let params = hash.split('&')
        // let id = 0;
        // //解析浏览器路径参数
        // params.forEach(item => {
        //     if(item.indexOf('line') > -1){
        //         id = item.split('=')[1]
        //     }
        // })
        // if(id){
        //     //请求线路
        //     getRecommendList().then(res => {
        //         let { list } = res
        //         console.log(list)
        //         //赋值线路数组
        //         this.linesList = list
        //         console.log(JSON.parse(list[0].points))
        //         //当前线路
        //         let line = list.find(item => {
        //             return item.id == id
        //         })
        //         //当前线路下标
        //         this.currentLine = list.findIndex(item => {
        //             return item.id == id
        //         })
        //         //判断开滦路线
        //         if(id == 7){
        //             let kl = this.quyus[1]
        //             this.mapTopClick(kl, false)
        //         }
        //         this.lines = JSON.parse(line.points).paths
        //         this.showGrid = false
        //         this.showLines = true
        //         this.markers.splice(0)
        //     })
        // }else{
        //     this.changeList(this.quyus[0])
        // }
    },
    methods:{
        closeQrcode(){
            this.qrcode = null
        },
        goThere(item){
            let url = 'https://jqadmin.tangshannanhu.com/weixintest/wq4/index?'
            let name = item.title || item.name
            let p = `name=${name}&lat=${item.latitude}&lng=${item.longitude}`
            let code = url + encodeURIComponent(p)
            this.qrcode = code
            this.mapItem = ''
            this.showListScroll = false
        },
        handleTouchStart(e){
            // console.log(e)
            e.stopPropagation()
            if(e.touches && e.touches.length>1){
                e.preventDefault()
            }
        },
        handleTouchMove(e){
            console.log(e)
            if(e.touches && e.touches.length>1){
                e.preventDefault()
                e.stopPropagation()
            }
        },
        changeToast(){
            this.showToast = false
        },
        //关闭推荐线路
        closeClick(){
            this.showLines = false
        },
        //切换线路
        changeIndex(type){
            if(type >= 1){
                this.currentLine = this.currentLine < this.linesList.length -1 ? this.currentLine + 1 : this.linesList.length - 1
            }else{
                this.currentLine = this.currentLine <=0 ? 0 : this.currentLine - 1
            }
            let index = this.currentLine
            console.log(index,'++++++')
            let quyus = null
            if(this.linesList[index].id == 7){
                quyus = this.quyus[1]
            }else{
                quyus = this.quyus[0]
            }
            this.topIndex = quyus.id
            this.centerLatLng = this.quyus[quyus.id]
            console.log(index,'++++++')
            this.lines = JSON.parse(this.linesList[index].points).paths
        },
        //点击推荐线路按钮
        recommendClick(){
            getRecommendList().then(res => {
                let { list } = res
                console.log(list)
                this.linesList = list
                console.log(JSON.parse(list[0].points))
                this.lines = JSON.parse(list[0].points).paths
                this.showGrid = false
                this.showLines = true
                this.markers.splice(0)
            })
        },
        pauseAudio(){
            let audio = document.querySelectorAll('audio')[0]
            console.log(audio)
            if(audio && !audio.paused){
                audio.pause()
                //查找mapModal
                let childs = Array.from(this.$children)
                // console.log(childs)
                let mapModal = childs.find(item => {
                    return item.durationTime
                })
                console.log(mapModal)
                // 重置播放图标
                if(mapModal){
                    mapModal.playing = false
                }
            }
            
        },
        closeMapModal(){
            this.mapItem = null
        },
        markerClick(id){
            console.log("父组件接收marker",id)
            //赋值mapItem
            let mapItem = this.mapList.find(item=>{
                return item.id == id
            })
            this.mapItem = mapItem
            let {latitude, longitude} = mapItem
            this.centerLatLng2 = { lat: latitude, lng: longitude}
            this.pauseAudio()
        },
        //************  渲染地图markers  ***********
        initMap(){
            this.markers.splice(0)
            this.mapList.length > 0 ? this.markers = [...this.mapList] : ''
        },
        // 关闭景点列表弹窗
        closeListScroll(){
            this.showListScroll = false
        },
        //请求景点
        getSceneList(spotType){
            getSceneList({spotType}).then(res => {
                let { list } = res.page
                list.forEach(item => {
                    item.name = item.title
                    item.imgUrl = item.banner
                })
                this.mapList = list
                this.showListScroll = true
                this.closeGrid()
                this.initMap()
            })
        },
        //请求餐饮或娱乐
        getRestList(){
            getRestList({
                type: this.activeGrid.type
            }).then(res => {
                let { list } = res.page
                //判断开滦景区有没有对应的服务
                if(this.topIndex == 1){
                    console.log("开滦==================")
                    let filterList = list.filter(item => {
                        return item.spotType == 2
                    })
                    console.log(filterList)
                    this.mapList = filterList
                    this.showListScroll = filterList.length > 0
                    this.closeGrid()
                    this.initMap()
                    if(filterList.length == 0){
                        this.showToast = true
                    }
                    return
                }
                console.log("南湖=================")
                list.forEach(item => {
                    item.name = item.title
                })
                this.mapList = list
                this.showListScroll = true
                this.closeGrid()
                this.initMap()
            })
        },
        //切换景点、餐饮···
        changeList(item){
            let type = item.id
            if(type == 0){
                //请求景点
                this.getSceneList(this.quyus[this.topIndex]['type'])
                return
            }
            if(type == 1 || type == 4){
                //请求餐饮或娱乐
                this.getRestList()
                return
            }
            getList({
                type: this.activeGrid.type
            }).then(res => {
                console.log(res)
                let list = res.data
                //判断开滦景区有没有对应的服务
                if(this.topIndex == 1){
                    console.log("开滦==================")
                    let filterList = list.filter(item => {
                        return item.spotType == 2
                    })
                    this.mapList = filterList
                    this.showListScroll = filterList.length > 0
                    this.closeGrid()
                    this.initMap()
                    if(filterList.length == 0){
                        this.showToast = true
                    }
                    return
                }
                console.log("南湖==================")
                this.mapList = list
                this.showListScroll = true
                this.closeGrid()
                this.initMap()
            })
        },
        //GRID点击切换景点、餐饮···
        gridItemClick(item){
            this.showLines = false
            this.mapList.splice(0)
            this.activeGrid = item
            this.changeList(item)
        },
        closeGrid(){
            this.showGrid = false
        },
        //点击侧边按钮打开Grid
        openGrid(){
            this.pauseAudio()
            this.showGrid = true
            this.showLines = false
            //隐藏景点列表弹窗
            this.showListScroll = false
            //隐藏点击marker展示的景点弹窗
            this.mapItem = null
        },
        //切换南湖、开滦
        mapTopClick(item, showList = true){
            if(item.id == this.topIndex || this.showLines) return
            this.topIndex = item.id
            this.centerLatLng = this.quyus[item.id]
            // this.centerLatLng2 = { lat: this.quyus[item.id]['lat'], lng: this.quyus[item.id]['lng']}
            if(!showList) return
            this.changeList(this.activeGrid)
        }
    }
}
</script>

<style scoped lang="scss">
    .container{
        width: 100%;
        height: 100vh;
    }
    

    .map-top{
        width: 100%;
        height: 80px;
        background: #28282A;
        color: #fff;
        .top-btn{
            width: 50%;
            height: 80px;
            text-align: center;
            line-height: 80px;
            position: relative;
        }
        .btn-active{
            color: rgba(251,229,74, 1);
            &::after{
                content: '';
                width: 100%;
                height: 4px;
                background: rgba(251,229,74, 1);
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
    .map-container{
        height: calc(100vh - 80px);
    }
    .side-btn{
        position: fixed;
        right: 0;
        bottom:750px;
        width: 105px;
        height: 105px;
        z-index: 9999999;
        background: #FBE54A;
        border-radius: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon{
        color: red;
    }
    .qrcode{
        width: 200px;
        height: 200px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10001;
        background: #28282A;
        border-radius: 20px;
        text-align: center;
        padding: 20px 20px;
        img{
            width: 60%;
            display: block;
            margin: auto;
        }
        div{
            color: #fff;
            margin: 12px auto 0;
            width: 80%;
            line-height: 26px;
        }
        .close{
            position: absolute;
            bottom: -98px;
            left: 50%;
            transform: translateX(-50%);
            width: 80px!important;
            height: 80px!important;
        }
    }
</style>