import server from "../utils/request";

// 获取停车、自行车、服务、出入、卫生间、游船、电瓶车列表
export function getList(params){
    return server({
        url:'/merchant/getListByType',
        method:'GET',
        params
    })
}

//获取景点列表spotType=1代表南湖，2代表开滦
export function getSceneList(params){
    return server({
        url:'/app/appoint/spotList?page=1&limit=100',
        method:'GET',
        params
    })
}

//获取餐饮(type=1)、娱乐(type=3)列表
export function getRestList(params){
    return server({
        url:'/app/product/list?page=1&limit=100',
        method:'GET',
        params
    })
}

//获取推荐线路列表
export function getRecommendList(){
    return server({
        url:'/line/allLineList',
        method:'GET'
    })
}

