<template>
    <!-- <transition name="fade"> -->
        <div class="toast" v-show="showToast">本区域暂无该服务</div>
    <!-- </transition> -->
</template>

<script>
export default {
    props:{
        showToast: false
    },
    watch:{
        showToast(){
            if(this.showToast){
                setTimeout(() => {
                    this.$emit("changeToast")
                }, 3000);
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .toast{
        width: auto;
        padding: 15px 30px;
        background: rgba(0, 0, 0, .8);
        color: #fff;
        border-radius: 10px;
        font-size: 14px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 9999999;
    }
</style>