/**
* @name 用于展示地图路线
* @description 
* @params {}
* @return { void }
* @author lvfuhao
* @version 2021-05-03 16:36:35 星期一
*/
<template>
  <section id="container" ref="mapContainer"></section>
</template>

<script>
import {
  initAMap,
  gzpolyline,
  markerLayer,
  destroyMap,
  setMapCenter,
  markerClick
} from "./handleTMap";
export default {
  props: {
    latLng: {
      type: Array,
      default: () => [],
    },
    markers: {
      type: Array,
      default: () => [],
    },
    centerLatLng: {
      type: Object,
      default: () => {}
    },
    centerLatLng2: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    markerClick(){
      console.log('点击事件',markerClick())
      // console.log(id)
    },
    latLng() {
      if (this.latLng.length) {
        gzpolyline(this.latLng, String(Math.floor(Math.random()*100)));
      }
    },
    markers() {
      if (this.markers.length > 0) {
        let markObj = markerLayer(this.markers,true);
        // markObj.on('click',(e)=>{
        //   console.log('MARKER 点击',e)
        //   this.$emit('markerClick',e.geometry.id)
        // })
      }
    },
    centerLatLng(){
      if(this.centerLatLng.lat){
        initAMap(this.centerLatLng, "container", this)
      }
    },
    centerLatLng2(){
      if(this.centerLatLng2.lat){
        setMapCenter(this.centerLatLng2.lng, this.centerLatLng2.lat)
      }
    }
  },
  mounted() {
    // initAMap(118.16286, 39.597875, "container");
    // setMapCenter(39.597875, 118.16166);
  },
  destroyed() {
    destroyMap();
  },
};
</script>

<style scoped lang="scss">
#container {
  width: 100%;
  height: 100%;
}
// /deep/会报错,::v-deep效果一样
//隐藏放大缩小
::v-deep .tmap-zoom-control{
    display: none;
}
//隐藏指南针
::v-deep .rotate-circle{
    display: none;
}
//隐藏比例尺
::v-deep .tmap-scale-control{
  display: none;
}
//隐藏logo
::v-deep .logo-text{
    display: none;
}
//隐藏logo
::v-deep a img{
    display: none;
}
</style>