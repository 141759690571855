import axios from "axios";

const server = axios.create({
    baseURL: 'https://jqadmin.tangshannanhu.com/api',
    timeout: 6000
})

server.interceptors.response.use(
    (res) => {
        if(res.status == 200){
            return res.data
        }
    },
    (err) => {
        return Promise.reject(err)
    }
);

export default server;