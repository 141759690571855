var TMap = window.TMap;
// import TMap from "TMap";
export let _AMap = null;
export let MapCenter = null;
export let markerList = [];
let that = this
let oldLine = null,
  markObj = null,
  lineObj = null,
  label = null,
  infoArr = [];
/**
 * @name 初始化坐标
 * @param {*} lng
 * @param {*} lat
 * @param {*} ele
 */
export function markerLayer(mapsarray, flag) {
  if (flag && lineObj) {
    lineObj.remove(oldLine);
  }
  markerList = [...mapsarray]
  let level = _AMap.getZoom()
  let markers = markerList.filter(item => {
    return item.mapSmall <= level && level <= item.mapBig
  })
  
  //创建并初始化MultiMarker
  var marr = [];
  var infoarr = [];
  if(infoArr.length){
    infoArr.forEach(item => {
      item.setMap(null)
    })
    infoArr = []
  }
  console.log(markers,"+++++++++++++")
  markers.map(function(value) {
    let lat = Number(value.latitude)
    let lng = Number(value.longitude)
    marr.push({
      id: value.id, //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
      styleId: "myStyle", //指定样式id
      position: new TMap.LatLng(lat, lng), //点标记坐标位置
      properties: {
        //自定义属性
        title: "mark",
      },
    });
    // 信息窗口
    let info = new TMap.InfoWindow({
        map: _AMap,
        enableCustom: true,
        id:'myinfowindow_'+ value.id,
        position: new TMap.LatLng(lat, lng),
        content:`<div style="z-index: 1099;height:15px;max-width:150px;padding:5px;background:rgba(255,255,255,0.6);border:#ccc solid 1px;border-radius: 5px;  line-height:15px;font-size:12px;position:absolute;top:20px;left:10px;">${value.name}</div>`,
        offset: { x: 0, y: 50 }
    }) ;
    infoarr.push(info)
  });
  infoArr = [...infoarr]
  if (markObj) {
    markObj.setMap(null);
  }
  markObj = new TMap.MultiMarker({
    map: _AMap, //指定地图容器
    //样式定义
    styles: {
      //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
      myStyle: new TMap.MarkerStyle({
        width: 50, // 点标记样式宽度（像素）
        height: 50, // 点标记样式高度（像素）
        "src": require('@/assets/images/marker-0.png'), //图片路径
        //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
        anchor: {
          x: 16,
          y: 10,
        },
      }),
    },

    //点标记数据数组
    geometries: marr,
  });
  console.log('markObj：',markObj)
  markObj.on('click', (e)=>{
    console.log('marker点击js',e)
    console.log(that)
    that.$emit('markerClick',e.geometry.id)
  })
  // if (label) {
  //   label.setMap(null);
  // }
  // label = new TMap.MultiLabel({
  //   id: "label-layer",
  //   map: _AMap,
  //   enableCollision: true,
  //   styles: {
  //     label: new TMap.LabelStyle({
  //       color: "#fff", //颜色属性
  //       size: 18, //文字大小属性
  //       offset: {
  //         x: 0,
  //         y: 30,
  //       }, //文字偏移属性单位为像素
  //       angle: 0, //文字旋转属性
  //       alignment: "center", //文字水平对齐属性
  //       verticalAlignment: "middle", //文字垂直对齐属性
  //     }),
  //   },
  //   geometries: labelarr,
  // });
  return markObj
}

export function markerClick(id){
  return id
}
export function destroyMap() {
  if (lineObj) lineObj.destroy();
  if (markObj) markObj.destroy();
  oldLine = null;
  markObj = null;
  lineObj = null;
  label = null;
}

export function gzpolyline(mapsarray, id) {
  var parray = [];
  mapsarray.map(function(value) {
    parray.push(new TMap.LatLng(value.latitude, value.longitude));
  });
  if (lineObj) {
    lineObj.remove(oldLine);
    oldLine = id;
    lineObj.add({
      id,
      styleId: "style_blue", //绑定样式名
      paths: [parray],
    });
  } else {
    oldLine = id;
    lineObj = new TMap.MultiPolyline({
      id: "polyline-layer", //图层唯一标识
      map: _AMap, //绘制到目标地图
      //折线样式定义
      styles: {
        style_blue: new TMap.PolylineStyle({
          color: "#3777FF", //线填充色
          width: 5, //折线宽度
          borderWidth: 0, //边线宽度
          borderColor: "#3777FF", //边线颜色
          lineCap: "round", //线端头方式
        }),
      },
      //折线数据定义
      geometries: [
        {
          //第1条线
          id, //折线唯一标识，删除时使用
          styleId: "style_blue", //绑定样式名
          paths: [parray],
        },
      ],
    });
  }
}
export function setMapCenter(lng, lat) {
  var center = new TMap.LatLng(lat, lng); //设置中心点坐标
  MapCenter = center;
  _AMap.setCenter(MapCenter);
  // _AMap.zoomTo(17);
}
export function setMapBoundary(northeast, southwest) {
  let ne = new TMap.LatLng(northeast.lat,northeast.lng);
  let sw = new TMap.LatLng(southwest.lat,southwest.lng);
  // var center = new TMap.LatLng(lat, lng); //设置边界
  // MapCenter = center;
  _AMap.setCenter(MapCenter);
  _AMap.zoomTo(15);
}
export function initAMap(latLng, ele, vueThis) {
  console.log(_AMap,"--------------")
  that = vueThis
  let lat = latLng.lat
  let lng = latLng.lng
  let ne = new TMap.LatLng(Number(latLng.neLat),Number(latLng.neLng));
  let sw = new TMap.LatLng(Number(latLng.swLat),Number(latLng.swLng));
  var center = new TMap.LatLng(Number(lat), Number(lng)); //设置中心点坐标
  // console.log(ne, sw, center, "+++++++++++++++")
  MapCenter = center;
  if(_AMap){
    _AMap.setBoundary(new TMap.LatLngBounds(sw,ne))
    _AMap.setCenter(center)
    console.log("0000",latLng.scale)
    _AMap.zoomTo(latLng.scale)
    return
  }
  //初始化地图
  _AMap = new TMap.Map(ele, {
    center: center,
    zoom: 15,
    minZoom:12,
    maxZoom:18,
    viewMode:'2D',
    zoomControl: false,
    attributionControl: false,
    baseMap: {
      type: "vector",
      features: ["base"], // 隐藏矢量文字
    },
    boundary:new TMap.LatLngBounds(sw,ne),
  });
  new TMap.ImageTileLayer({
    getTileUrl: function(x, y, z) {
      //拼接瓦片URL
      var url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/wp/" +
        z +
        "/" +
        x +
        "_" +
        y +
        ".png";
      return url;
    },
    tileSize: 256, //瓦片像素尺寸
    minZoom: 8, //显示自定义瓦片的最小级别
    maxZoom: 18, //显示自定义瓦片的最大级别
    visible: true, //是否可见
    zIndex: 5000, //层级高度（z轴）
    opacity: 0.98, //图层透明度：1不透明，0为全透明
    map: _AMap, //设置图层显示到哪个地图实例中
  });
  _AMap.on('click', function(e){
    // console.log(e)
  })
  _AMap.on('zoomend', function(e){
    console.log(e,"zoomed+++++")
    console.log(markerList)
    markerLayer(markerList)
  })
  setTimeout(() => {
    let container = window.document.getElementsByClassName("map");
    container = container ? container[0] : null;
    container &&
      container.addEventListener("mousewheel", function(e) {
        e.stopPropagation();
        return false;
      });
  }, 1000);
}

export function clearExample() {
  oldLine = null;
  markObj = null;
  lineObj = null;
}

/**
 * @name 添加自定义窗体
 * @param { Array } HTMLArr 窗口模板数组
 * @return {Array}
 */
// export function handlesInfoWindow(HTMLArr) {
//     return HTMLArr.map( html => {
//         return new AMap.InfoWindow({
//             isCustom: true,  //使用自定义窗体
//             content: createInfoWindow(title,content),  //传入 dom 对象，或者 html 字符串
//             offset: new AMap.Pixel(16, -50)
//         });
//     })

// }

/**
 * @name 构建自定义信息窗体
 * @param {String} title
 * @param {Array} content
 */
// function createInfoWindow(title, content) {
//     var info = document.createElement("div");
//     info.className = "custom-info input-card content-window-card";

//     //可以通过下面的方式修改自定义窗体的宽高
//     //info.style.width = "400px";
//     // 定义顶部标题
//     var top = document.createElement("div");
//     var titleD = document.createElement("div");
//     var closeX = document.createElement("img");
//     top.className = "info-top";
//     titleD.innerHTML = title;
//     closeX.src = "https://webapi.amap.com/images/close2.gif";
//     closeX.onclick = closeInfoWindow;

//     top.appendChild(titleD);
//     top.appendChild(closeX);
//     info.appendChild(top);

//     // 定义中部内容
//     var middle = document.createElement("div");
//     middle.className = "info-middle";
//     middle.style.backgroundColor = 'white';
//     middle.innerHTML = content;
//     info.appendChild(middle);

//     // 定义底部内容
//     var bottom = document.createElement("div");
//     bottom.className = "info-bottom";
//     bottom.style.position = 'relative';
//     bottom.style.top = '0px';
//     bottom.style.margin = '0 auto';
//     var sharp = document.createElement("img");
//     sharp.src = "https://webapi.amap.com/images/sharp.png";
//     bottom.appendChild(sharp);
//     info.appendChild(bottom);
//     return info;
// }

//关闭信息窗体

// function closeInfoWindow() {
//     _AMap.clearInfoWindow();
// }
