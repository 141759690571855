<template>
    <div class="list-scroll"
        @touchstart="stopTouchStart"
        @touchmove="stopTouchMove">
        <!-- top最小到25% 最大到50% -->
        <div class="list-wrapper"
            @touchstart="stopTouchStart"
            @touchmove="stopTouchMove"
            :style="{top:top+'%'}">
            <!-- 关闭按钮 -->
            <svg-icon icon-class="close" className='close-icon' @click.stop="closeListScroll" />
            <div class="list-main">
                <div class="touch" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
                    <img src="../../assets/images/touch.png" class="touch-icon" alt="">
                </div>
                <div class="scroll">
                    <div class="list-item"
                        @touchstart="stopTouchStart"
                        @touchmove="stopTouchMove"
                        v-for="(item,index) in mapList"
                        :key="index"
                    >
                        <div class="item-name">{{item.name}}</div>
                        <img v-if="item.imgUrl" class="item-img" :src="fileUrl + item.imgUrl" alt="">
                        <div class="item-bottom">
                            <span class="item-address" v-if="item.address">{{item.address}}</span>
                            <span @click="goThere(item)">去这里 ></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        mapList:[]
    },
    data(){
        return {
            fileUrl: 'https://jqadmin.tangshannanhu.com/api/upload/',
            top:50,
            clientHeight: 1920
        }
    },
    mounted(){
        this.clientHeight = document.body.clientHeight || window.screen.height
        console.log('浏览器高度：',this.clientHeight)
    },
    watch:{
        mapList(){
            //当列表内容为空时，重置top值
            if(this.mapList.length == 0){
                this.top = 50
            }
        }
    },
    methods:{
        goThere(item){
            this.$emit('goThere', item)
        },
        stopTouchStart(e){
            // console.log(e)
            e.stopPropagation()
            if(e.touches && e.touches.length>1){
                e.preventDefault()
            }
        },
        stopTouchMove(e){
            // console.log(e)
            if(e.touches && e.touches.length>1){
                e.preventDefault()
                e.stopPropagation()
            }
        },
        closeListScroll(){
            this.$emit('closeListScroll')
        },
        handleTouchStart(e){
            // console.log(e)
        },
        handleTouchMove(e){
            // console.log(e)
            if(e.touches && e.touches.length > 1){
                e.preventDefault()
                return false
            }
            let mh = e.changedTouches[0]['pageY']
            let rate = mh / this.clientHeight
            if(rate <= 0.25 || rate >= 0.75){
                return false;
            }
            this.top = rate * 100
        },
        handleTouchEnd(e){
            // console.log(e)
        }
    }
}
</script>

<style scoped lang="scss">
    .list-scroll{
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        overflow: hidden;
        .list-wrapper{
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            width: 100%;
            .list-main{
                background: #28282A;
                border-radius: 30px 30px 0 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                padding-bottom: 100px;
                &::-webkit-scrollbar{
                    display: none;
                }
                // max-height: 50vh;
                // overflow-y: scroll;
                .scroll{
                    max-height: 70vh;
                    overflow-y: scroll;
                    &::-webkit-scrollbar{
                        display: none;
                    }
                }
            }
        }
    }
    .list-item{
        padding: 10px 5px;
        border-bottom: 1px solid #ccc;
        color: #fff;
        .item-name,.item-bottom{
            padding: 10px 30px;
        }
        .item-name{
            font-weight: bold;
            font-size: 28px;
            line-height: 40px;
        }
        .item-img{
            height: 390px;
            width: 100%;
            border-radius: 20px;
            object-fit: cover;
        }
        .item-bottom{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 19px;
            font-weight: bold;
            color: rgba(251,229,74, 1);
            margin-top: 10px;
            .item-address{
                color: #fff;
            }
        }
    }
    .touch{
        height: 50px;
        background: #28282A;
        border-radius: 30px 30px 0 0;
        // position: relative;
        .touch-icon{
            display: block;
            margin: 0 auto;
            position: absolute;
            left: 50%;
            top: 20px;
            transform: translate(-50%,0);
        }
    }
    
    .close-icon{
        width: 80px!important;
        height: 80px!important;
        position: absolute;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
    }
</style>